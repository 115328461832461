body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
	height: 100%;
}

#root {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
}

.carousel {
	width: 70vw;
	height: 60vh;
	position: relative;
	margin: auto;
	overflow: hidden;
}

.carousel__control {
	font-size: 0;
	line-height: 0;
	width: 2.5rem;
	height: 2.5rem;
	display: block;
	position: absolute;
	top: 50%;
	padding: 0;
	border: 0;
	margin-top: -1.25rem;
	z-index: 2;
	background-color: #fff;
	border-radius: 0.25rem;
	box-shadow: none;
	appearance: none;
}

.carousel__control::before {
	content: '';
	width: 1.25rem;
	height: 1.25rem;
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	border-width: 0 0 0.125rem 0.125rem;
	border-style: solid;
	border-color: transparent transparent #000 #000;
	margin-top: -0.625rem;
}

.carousel__control--prev {
	right: 100%;
	margin-right: -2.5rem;
}

.carousel__control--prev::before {
	margin-left: -0.25rem;
	transform: rotate(45deg);
}

.carousel__control--next {
	left: 100%;
	margin-left: -2.5rem;
}

.carousel__control--next::before {
	margin-left: -1rem;
	transform: rotate(-135deg);
}

.carousel__wrapper,
.carousel__slides,
.carousel__slide {
	height: 100%;
}

.carousel__wrapper {
	width: 60vw;
	position: relative;
	margin: auto;
}

.carousel__slides {
	font-size: 0;
	line-height: 0;
	white-space: nowrap;
	transition: transform 0.4s ease-in-out;
	transform: translateX(0);
}

.carousel__slide {
	font-size: 1rem;
	line-height: 1.25;
	flex: 0 0 50vw;
	width: 60vw;
	display: inline-block;
	vertical-align: top;
	border-width: 0 1rem;
	border-style: solid;
	border-color: #fff;
	background-repeat: no-repeat;
	background-position: 0 0;
	background-size: cover;
	box-sizing: border-box;
	backface-visibility: hidden;
	position: relative;
}

.carousel__slide-meta {
	text-align: center;
	display: flex;
	flex-flow: row nowrap;
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
	background-color: rgba(255, 255, 255, 0.5);
}

.carousel__slide-meta p {
	flex: 0 0 33.33%;
	padding: 1rem;
	box-sizing: border-box;
}
